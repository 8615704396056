import React from "react";
import SimulateInsuranceStyle from "./simulateInsuranceStyle";
import Button from "../../../../components/button/button";
import Text from "../../../../theme/font";

const SimulateInsurance = ({ openPopup }) => {
  return (
    <SimulateInsuranceStyle>
      <div className="necessary-actions-container">
        <div className="simulate-insurance-section box-container">
          <div className="simulate-insurance-section-container">
            <div className="simulate-insurance-section-header">
              <Text
                variant="titleFont"
                className="simulate-insurance-section-heading"
              >
                ¿Quieres simular otro seguro?
              </Text>
            </div>
            <Text className="simulate-button-container" variant="labelFont">
              <Button
                value="Simular"
                className="simulate-button"
                click={() => openPopup(true)}
              />
            </Text>
          </div>
        </div>
      </div>
    </SimulateInsuranceStyle>
  );
};

export default SimulateInsurance;
