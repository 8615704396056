import { React } from "react";
import Button from "../../../../components/button/button";
import ImageConstants from "../../../../constants/imageConstants/image_constants";
import OurProposalSectionStyle from "./ourProposalSectionStyle";
import Text from "../../../../theme/font";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from "swiper";

const OurProposalSection = ({
  data,
  click,
  popUpClick,
  connectData,
  responsiveData,
}) => {
  const bankData = data.info;
  const videoData = data?.info?.banco?.enlacevideo;

  const videoScrollHandler = () => {
    click();
  };

  const popUpClickHandler = () => {
    popUpClick();
  };

  const swiperHandler = () => {
    return (
      <div className="responsive-container">
        <Swiper
          pagination={{
            dynamicBullets: true,
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {responsiveData &&
            responsiveData.map((value) => {
              return (
                <SwiperSlide>
                  <div className="proposal-section-container"  key={Math.random()}>
                    <div className="proposal-section-header">
                      <div className="proposal-section-heading">
                        <Text variant="titleFont">
                          Resumen de tu aportación
                        </Text>
                      </div>
                      <div className="proposal-section-description">
                        <Text variant="normal"></Text>¡Bien hecho! Los
                        inversores que aportan a su inversión de manera
                        periódica obtienen, de media, una rentabilidad un 70%
                        mejor que los que no lo hacen
                      </div>
                    </div>
                    <div className="responsive-investment-details-container">
                      {value.number && (
                        <div className="fund-details">
                          <div className="fund-icon">
                            <img src={ImageConstants.walletIcon} alt="x" />
                          </div>
                          <div className="fund-info">
                            <div className="fund-title">
                              <Text variant="normal">Vas a aportar a</Text>
                            </div>
                            <div className="fund-count">
                              <Text variant="titleFont">
                                {bankData.numaportaciones > 1
                                  ? `${bankData.numaportaciones} fondos`
                                  : `${bankData.numaportaciones} fondo`}
                              </Text>
                            </div>
                          </div>
                        </div>
                      )}
                      {value.bankIcon && (
                        <div className="bank-details">
                          <div className="bank-icon">
                            <img src={ImageConstants.bankIcon} alt="x" />
                          </div>
                          <div className="bank-info">
                            <div className="bank-title">
                              <Text variant="normal">
                                En tu entidad bancaria
                              </Text>
                            </div>
                            {bankData && (
                              <div className="bank-img">
                                <img src={bankData.banco.image} alt="x" />
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {value.videoLink && (
                        <div className="help-details">
                          <div className="video-icon">
                            <img src={ImageConstants.videoIcon} alt="x" />
                          </div>
                          <div className="help-info">
                            <div className="help-title">
                              <Text variant="normal">
                                ¿Cómo realizo las inversiones?{" "}
                              </Text>
                            </div>
                            <div className="help-tutorial">
                              <Text
                                variant="normal"
                                onClick={videoScrollHandler}
                              >
                                {videoData
                                  ? "Ver vídeo tutorial"
                                  : "Ver receta tutorial"}
                              </Text>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    );
  };

  return (
    <OurProposalSectionStyle>
      <div className="proposal-section-container main-container">
        <div className="proposal-section-header">
          <div className="proposal-section-heading">
            <Text variant="titleFont"> Resumen de tu aportación</Text>
          </div>
          <div className="proposal-section-description">
            <Text variant="normal"></Text>¡Bien hecho! Los inversores que
            aportan a su inversión de manera periódica obtienen, de media, una
            rentabilidad un 70% mejor que los que no lo hacen
          </div>
        </div>
        <div className="investment-details-container">
          <div className="fund-details">
            <div className="fund-icon">
              <img src={ImageConstants.walletIcon} alt="x" />
            </div>
            <div className="fund-info">
              <div className="fund-title">
                <Text variant="normal">Vas a aportar a</Text>
              </div>
              <div className="fund-count">
                {bankData && (
                  <Text variant="titleFont">
                    {bankData.numaportaciones > 1
                      ? `${bankData.numaportaciones} fondos`
                      : `${bankData.numaportaciones} fondo`}
                  </Text>
                )}
              </div>
            </div>
          </div>
          <div className="bank-details">
            <div className="bank-icon">
              <img src={ImageConstants.bankIcon} alt="x" />
            </div>
            <div className="bank-info">
              <div className="bank-title">
                <Text variant="normal">En tu entidad bancaria</Text>
              </div>
              {bankData && (
                <div className="bank-img">
                  <img src={bankData.banco.image} alt="x" />
                </div>
              )}
            </div>
          </div>
          <div className="help-details">
            <div className="video-icon">
              <img src={ImageConstants.videoIcon} alt="x" />
            </div>
            <div className="help-info">
              <div className="help-title">
                <Text variant="normal">¿Cómo realizo las inversiones? </Text>
              </div>
              <div className="help-tutorial">
                <Text variant="normal" onClick={videoScrollHandler}>
                  {videoData ? "Ver vídeo tutorial" : "Ver receta tutorial"}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
      {swiperHandler()}
      {connectData === "manual" && (
        <div className="bought-btn">
          <Button
            value="Ya he comprado mis fondos"
            className="btn-div"
            click={popUpClickHandler}
          />
        </div>
      )}
      {connectData === "ironia" && (
        <div className="bought-btn">
          <Button
            value="Comprar"
            className="btn-div"
            click={popUpClickHandler}
          />
        </div>
      )}
    </OurProposalSectionStyle>
  );
};

export default OurProposalSection;
