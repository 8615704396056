import styled from "styled-components";

const AutopilotStyle = styled.div`
  padding-right: 2rem;
  @media (max-width: 1040px) {
    padding: 0;
  }
  .autopilot-container {
    display: flex;
    height: fit-content;
    overflow:hidden;
    @media (max-width: 1080px) {
      flex-direction: column;
      align-items: center;
      margin-bottom:1rem;
    }

    .autopilot-welcome-section {
        background-color:#1A2E40;
        color:white;
    }
    
    .autopilot-blue-section {
        background-color:#1A2E40;
        background-clip: padding-box;
        color:white;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-height: fit-content;
        width: 25%;
        @media (max-width: 1080px) {
          width: 100%;
          display:none;
        }
        padding: 2rem 1.5rem;
    }

    .autopilot-white-section{
        background-color:white;
        height: fit-content;
        max-height: fit-content;
        width: 75%;
        @media (max-width: 1080px) {
          width: 100%;
        }
        padding: 2rem 1.5rem;
    }

    .autopilot-blue-section-mobile {
      background-color:#1A2E40;
      background-clip: padding-box;
      color:white;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-height: fit-content;
      width: 100%;
      @media (min-width: 1081px) {
        display:none;
      }
      padding: 2rem 1.5rem;
  }

    .invited-section {
      padding: 1rem 1rem;
      min-width: 18.12rem;
      height: fit-content;
      max-height: fit-content;
      @media (max-width: 1080px) {
        width: 100%;
        max-width: 25rem;
      }
    }
    .bold{
        font-weight:bold;
      }
  }
`;
export default AutopilotStyle;
